<template>
  <div>
    <div id="product-title" class="product-title">
      <img src="./../assets/message.png" alt="" />
      <div class="banner-text">
        {{ word.foot.company.PrivacyPolicy[language] }}
      </div>
    </div>
    <div class="content">
      <h1 class="terms-hdng">YONI-TECH PRIVACY POLICY</h1>
      <h2 class="terms-list-hdng">1. Introduction</h2>
      <p class="terms-desc">
        The protection of your privacy is important to Jiaxing Optimal IT Co.,
        Ltd. and its affiliates, (hereinafter, “YONI-TECH”, “we” or respectively
        “us” / “our”). Jiaxing Optimal IT Co., Ltd. including its corporate
        affiliates, (“YONI-TECH,”“we,”“our”） is a global provider of cloud
        communications that offers various services.we offer a complete and
        fully-configured unified communications solution for the delivery of
        end-to-end communications.We also provide our communications services in
        programmable modules delivered via APIs, enabling our customers to
        incorporate these services into their own applications to serve their
        own customers.
      </p>
      <p class="terms-desc">
        This Privacy Policy applies to our customers, prospective customers, our
        customers (both direct and indirect), job applicants, current and former
        employees, contractors, visitors to our company websites,and recipients
        of our e-mail communications.
      </p>
      <p class="terms-desc">
        When you use our websites or our services, you acknowledge and
        understand that YONI-TECH will collect, use, and share your information,
        as we describe in this Privacy Policy.
      </p>
      <p class="terms-desc">
        If you do not agree with this privacy policy, you must not use the
        Services.
      </p>
      <p class="terms-desc">
        This Privacy Policy will only apply to you if the Services are made
        available to you by the relevant YONI-TECH entity as set out in the
        Terms of Service.
      </p>
      <h2 class="terms-list-hdng">
        2. What Type Of Personal Information We Collect
      </h2>
      <p class="terms-desc">
        We collect your information in the following ways:
      </p>
      <p class="terms-desc">
        Information you provide directly to us. you subscribe to our service,
        sign up for our services, request us to contact you, or through other
        interactions with us (or for business customer, act as the company's
        business representative to register for Services), we may ask you for
        certain personal information that identifies or is linked or reliably
        linked to you (“Personal Data” ,)such as your name, postal address,
        e-mail address, telephone number, company name, job title, or payment
        information, as well as non-Personal Data. For instance, and without
        limitation:
      </p>
      <p class="terms-desc-dot">
        When you request support from us, we may also collect information from
        you, including Personal Data, such as contact information,
        documentation, screenshots, or other information you or we may believe
        is helpful to solving the issue.
      </p>
      <p class="terms-desc-dot">
        When you speak with our customer service or sales on the phone, your
        calls may be recorded and/or monitored for quality assurance, training
        and research purposes.
      </p>
      <p class="terms-desc-dot">
        In certain circumstances and depending on the country where you reside,
        we may collect your Social Security number from you for a limited
        purpose, such as for tax reporting relating to a payment for a customer
        referral or to facilitate an international money transfer.
      </p>
      <p class="terms-desc">
        Information we collect automatically when you visit our websites, our
        vendors and third-party services, such as our advertising and business
        providers, collect information about your visits to our websites and
        your interactions with our ads or content, together with information
        such as your IP address, location, cookies, and other tracking
        technologies (e.g., web beacons, device identifiers, pixels, and
        cross-device tracking). For more information, please see our Cookies
        Policy.
      </p>
      <p class="terms-desc">
        Information we get from third parties. Third party sources of
        information include, without limitation:
      </p>
      <p class="terms-desc-dot">
        Government websites or credit rating agencies. We may obtain your credit
        information from government websites or credit rating agencies in order
        to control our own credit risk in on boarding new customers.
      </p>
      <p class="terms-desc-dot">
        Website or social media platform . We also obtain where permitted by law
        contact information and other marketing lead information from third
        parties, websites or social media platforms and may combine it with
        information we have, for instance, to contact you or direct YONI-TECH
        marketing information to you.
      </p>
      <p class="terms-desc">
        Information about our customers users. Our customers and other third
        parties may also provide us with Personal Data and other information
        about our customers users and others. For example, we may receive
        Personal Data and other information from our customers, message senders,
        mobile network operators, databases with information relevant to mobile
        telephone numbers submitted to our services, and other third parties.
        This information may include, without limitation, telephone numbers,
        telephone numbers validity, corresponding device status (e.g., whether
        or not it is currently available for messaging), roaming status, carrier
        and country of location,for instance, to help us provide our services.
      </p>
      <p class="terms-desc">
        Information collected in connection with your use of services delivered
        via our platform. We and our service providers may collect Personal Data
        and other information in connection with your use of communications
        services delivered via our platform, including, without limitation:
      </p>
      <p class="terms-desc-dot">
        Communications usage information. This includes information about your
        communications delivered via our platform such as the time and duration
        of usage, source and destination identifiers, from/to phone numbers,
        completion status, location, IP address, and amount of usage.
      </p>
      <p class="terms-desc-dot">
        Communications content. To enable you to send and receive communications
        via our platform, we need to be able to handle the content of the
        messages, calls, and other communications channels used by you. This
        also includes, for example, voicemails, messages and call recordings
        recorded via our services.
      </p>
      <p class="terms-desc-dot">
        Device information. Where you have installed our software on your
        device, we collect device-specific information from you. This includes,
        for example, your hardware model, operating system version, firmware,
        network configuration, device identifier, IP address, device performance
        and mobile or wireless network information. We use the device
        information we collect in order to deliver and improve our services.
        Device information is also collected when you visit our websites, as
        more fully detailed above.
      </p>

      <p class="terms-desc">
        Mobile device camera, microphone and photos. If you use our mobile apps,
        we may request your permission to access the camera, microphone, and
        photos on your mobile device, to make and receive voice and video calls
        and messages and to send photos to others. You do not have to allow us
        to access these functions of your device, but if you do not, certain
        features of our mobile apps may not be available to you. You may at any
        time opt out from allowing this access via the privacy settings on your
        device.
      </p>

      <h2 class="terms-list-hdng">
        3. Why We Collect Personal Information And How We Use IT
      </h2>

      <p class="terms-desc">
        How we use the information we collect depends on the nature of the data,
        which of our services you use, how you use them, and specific
        preferences you may have communicated to us. We reserve the right to
        collect, share and otherwise use non-Personal Data for any purpose
        except where limited by applicable law, and to create non-Personal Data
        from Personal Data in order to do so. We list below the specific
        purposes for which we collect, use and otherwise process your Personal
        Data:
      </p>
      <p class="terms-desc-dot">
        To deliver our services. We use your Personal Data and other information
        as necessary to perform our obligations in delivering our services to
        our customers. This includes delivering your communications to the
        intended end user, processing transactions with you (such as billing),
        authenticating you when you log into our platform, providing customer
        support, and operating and maintaining our services. We also need your
        information to communicate with you about the services, including
        registration confirmations, purchase confirmations, expiration or
        renewal reminders, responding to your requests, and sending you notices,
        updates, security alerts, administrative messages, and other
        communications necessary to usage of the services.
      </p>
      <p class="terms-desc-dot">
        To carry out core activities relating to our services. To effectively
        deliver our services to you, we use your Personal Data and other
        information to engage in important supporting activities such as:
      </p>
      <p class="terms-desc-dot">
        a. billing and collections, including maintenance of records in the
        event of a subsequent billing dispute;
      </p>
      <p class="terms-desc-dot">
        b. preventing fraud, violations of our acceptable use policies, and
        unlawful activities;
      </p>
      <p class="terms-desc-dot">
        c. troubleshooting, quality control, and analytic; and
      </p>
      <p class="terms-desc-dot">
        d. monitoring the performance of our systems and platform.
      </p>
      <p class="terms-desc-dot">
        To improve our services and develop new services. We use your data in
        the provision of the services. We also use data we collect in existing
        services to improve and develop new services.
      </p>
      <p class="terms-desc-dot">
        To market, promote, and drive engagement of our products and services.
        We use Personal Data and other information about you to send promotional
        communications, such as e-mail messages, text messages, phone calls,
        faxes and postal mail, and for marketing when we have an adequate legal
        ground to do so. When prior opt-in consent is required by applicable
        laws, we will obtain such consent. We do not send marketing
        communication to our customers customers who are end users. Based on
        information we collect about you, we may decide whether and how to
        promote certain of our products or services to you over others.
      </p>
      <p class="terms-desc-dot">
        To comply with legal requirements. Applicable laws or regulations may
        require our processing of your Personal Data and other information, such
        as laws mandating retention of communications data, including
        disclosures required by law or legal process.
      </p>
      <p class="terms-desc-dot">
        To protect our legitimate business interests and legal rights. Where we
        believe it is necessary to protect our legal rights and interests, or
        the interests of others, we use Personal Data and other information
        about you in connection with legal claims, compliance, regulatory, and
        audit functions.
      </p>
      <p class="terms-desc-dot">
        According to your explicit consent. If we wish to use your Personal Data
        for certain purposes which require consent under applicable law, we will
        first seek out and obtain your consent.
      </p>
      <p class="terms-desc">What Personal Data do we collect?</p>
      <p class="terms-desc">
        When you visit and use our website, Yoni-tech may collect and Process
        the following types of Personal Data:
      </p>
      <p class="terms-desc">
        name, company name, title/ position/ role and contact details (telephone
        number, e-mail); your consent choices and preferences (to process
        Personal Data / use cookies / receive newsletter and other services
        requested by you); IP address, user-agent information, cookie
        identifiers and settings, your behavioral data (pages visited,
        interactions), tracking data (for details please see the Cookie Notice);
      </p>
      <h2 class="terms-list-hdng">4. Why do we process your Personal Data?</h2>
      <p class="terms-desc">
        There is always a legal basis or a permitted legitimate reason why we
        Process your Personal Data, such as:
      </p>
      <p class="terms-desc">To enable you to access and use our website;</p>
      <p class="terms-desc">
        To enhance user experience by providing personalized content and
        features;
      </p>
      <p class="terms-desc">
        To evaluate and improve the performance and design of the website and
        our services;
      </p>
      <p class="terms-desc">
        To collect analytics data for improving website functionality and
        content.
      </p>
      <p class="terms-desc">
        To track and manage user preferences and consent regarding cookies.
      </p>
      <p class="terms-desc">
        To promote the website in social media by using the social media
        plug-ins placed on the website.
      </p>
      <p class="terms-desc">
        To track marketing campaign results and responses;
      </p>
      <p class="terms-desc">
        To establish, exercise or defend legal claims, and/or to protect and
        assert our and your legal rights;
      </p>
      <p class="terms-desc">
        To comply with any legal obligation, we are subject to;
      </p>
      <p class="terms-desc">
        To enhance security and to protect us from fraud or other inappropriate
        conduct.
      </p>
      <p class="terms-desc">
        In all cases above Yoni-tech will limit the Processing of Personal Data
        to the extent required to fulfill the specific purpose.
      </p>
      <p class="terms-desc">
        Your respective Personal Data will be held in our website database or
        stored on the servers of our hosting services providers. Cookie data is
        retained based on the cookie's duration and is cleared upon expiration.
        Analysis data is retained for a limited period, for analysis and
        improvement purposes.
      </p>

      <h2 class="terms-list-hdng">
        5. What is the legal basis of Processing your Personal Data?
      </h2>
      <p class="terms-desc">
        With your use of this website we may Process your Personal Data based on
        the following legal bases: Consent - for processing of personal data to
        provide the services you requested; Consent - for processing of personal
        data to provide newsletter; Consent - for functional, performance,
        marketing and unclassified cookies, when you manage your cookie
        preferences, subscribe to Yoni-tech newsletter; Legitimate interest -
        for strictly necessary cookies, when you visit our website (in order to
        tailor it to the visitors needs and preferences) or use the social media
        plug-ins placed on the website (see Section 8). It is for example in our
        legitimate interest to make this website better for visitors and promote
        it via social media, provided that our legitimate interest does not
        outweigh your rights and interests;
      </p>
      <h2 class="terms-list-hdng">6. What are Your rights?</h2>
      <p class="terms-desc">
        With respect to your Personal Data that we Process, you have the
        following rights: Right to access - you may request us to provide you
        with a copy of your Personal Data, in general free of charge, in an
        electronic format; Right to rectification - you may request us to
        correct, update and/or otherwise amend your inaccurate or incomplete
        Personal Data; Right to object - you may object to the Processing of
        your Personal Data (in case Personal Data is processed on the legitimate
        interest legal basis); Right to restrict Processing - you may restrict
        the Processing of your Personal Data; Right to erasure - you may request
        us to erase your Personal Data; Right to data portability - you may ask
        us to transfer your Personal Data to you or to a third party designated
        by you (in case Personal Data is processed on the consent legal basis);
        Right to withdraw your consent - you may withdraw your consent (in case
        Personal Data is processed on the consent legal basis); Right to file a
        complaint - you may file a complaint to a data protection authority. In
        order to exercise any of the rights in relation of your Personal Data,
        please address your respective request to service@yoni-tech.com. The
        exercise of rights is subject to exemptions. In case any exemption
        applies, we will communicate this to you in a timely manner. Yoni-tech
        will comply with your justified request without undue delay and, in any
        event, within one month of the receipt of such request. This period may
        be extended up to two further months where necessary, taking into
        consideration the complexity and number of the requests we receive.
      </p>
      <h2 class="terms-list-hdng">7. Personal Data sharing with others</h2>
      <p class="terms-desc">
        We will respect and protect your privacy as set out in this Privacy
        Notice. To the extent reasonably necessary, we may disclose your
        Personal Data with: our affiliates/employees other third parties if any
        who support this website or otherwise are required to Process your
        Personal Data for the purpose of Yoni-tech's business operations; third
        parties who provide marketing and/or additional support services (please
        see Section 8 below and the cookie preference information in our Cookie
        Notice); law enforcement bodies, state agencies, courts, regulatory
        authorities or similar if required to be disclosed for compliance with a
        legal obligation to which we are subject, to protect your or third
        party's vital interests, or to exercise or defend our legal claims;
        other third parties, upon your prior written consent/instruction or on
        the basis of our legitimate interest.
      </p>
      <h2 class="terms-list-hdng">8. Cookies</h2>
      <p class="terms-desc">
        A cookie is a piece of data contained in a very small text file that is
        stored in your browser or elsewhere on your hard drive. Cookies allow
        YONI-TECH to identify your device as you navigate our websites or your
        account. This makes navigating and interacting with our websites or your
        account more efficient, easy and meaningful for you. By themselves,
        cookies do not identify you specifically. Rather, they recognize your
        web browser. So, unless you identify yourself specifically to YONI-TECH,
        like signing into your account, we dont know who you are just because
        you visited our website. YONI-TECH uses both session and persistent
        cookies. Session cookies are cookies that disappear from your computer
        or browser when you turn off your computer. Persistent cookies stay on
        your computer even after you are turned it off. Additionally, the
        cookies on our websites fall into three categories: (1) Required
        Cookies, (2) Functional Cookies, and (3) Advertising Cookies. To learn
        more about each category of cookie, you can visit our cookie consent
        tool by clicking on the "Cookie Preferences" link on the bottom right of
        the YONI-TECH website you are visiting.
      </p>
      <h2 class="terms-list-hdng">9. Dispute Resolution</h2>
      <p class="terms-desc">
        This Privacy Policy and any dispute or claim arising out of or in
        connection with it or its subject matter or formation (including
        non-contractual disputes or claims) shall be governed by and construed
        and enforced in accordance with the laws of Singapore.
      </p>
      <p class="terms-desc">
        Any dispute first arising in relation to the Privacy Policy and all
        claims and matters will be resolved primarily through negotiation
        between the Parties. If the resolution is not reached through
        negotiation after thirty (30) working days from the date of commencing
        amicable dispute resolution, one party may submit the dispute to be
        settled by the The Singapore International Arbitration Centre(SIAC).The
        proceedings shall be held in English. The arbitrators decision shall be
        final and binding.
      </p>
      <h2 class="terms-list-hdng">10. How To Contact Us</h2>
      <p class="terms-desc">
        If you have any questions, comments or suggestions regarding this
        privacy policy, please contact us via email: service@yoni-tech.com, and
        we will respond to your request within 15 days.
      </p>
    </div>
  </div>
</template>
<script>
import word from "./../assets/json/language.json";

export default {
  name: "Service",
  components: {},
  created() {
    this.language = localStorage.getItem("language");
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 可选的平滑滚动效果
    });
  },
  data() {
    return {
      language: "",
      word: word,
    };
  },
};
</script>


<style scoped lang="scss">
.dot {
  width: 20px;
  height: 20px;
  background: black;
  border-radius: 100%;
}
.product-title {
  width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 100px;
  background-color: #6c63ff;
  img {
    display: block;
    height: 300px;
    text-align: center;
  }
  .banner-text {
    position: absolute;
    left: 30%;
    top: 40%;
    font-size: 46px;
    color: #fff;
  }
}
.product-info {
  .product-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    img {
      width: 400px;
      flex-shrink: 0;
    }
    > div {
      width: 380px;
      line-height: 1.8;
      padding: 0 50px;
      color: #888;
      .product-subtitle {
        font-size: 30px;
        margin-bottom: 10px;
        color: #353535;
      }
    }
  }
}

.content {
  padding: 0px 40px 20px 40px;

  .terms-hdng {
    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 12px;
  }

  .terms-desc {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.32px;
    margin-bottom: 10px;
    margin-left: 20px;
    text-align: justify;
  }
  .terms-desc-dot {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.32px;
    margin-bottom: 10px;
    margin-left: 20px;
    text-align: justify;

    &:before {
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      background-color: black;
      border-radius: 100%;
      margin-right: 10px;
    }
  }

  .terms-list-hdng {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.36px;
    margin: 30px 0 10px;
  }
  .terms-list-hdng-small {
    color: #000;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 0.36px;
    margin: 30px 0 10px;
  }
  .terms-list-hdng-h3 {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.36px;
    margin: 30px 20px 10px 20px;
  }

  .terms-desc-small {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.32px;
    margin-bottom: 10px;
    margin-left: 40px;
    text-align: justify;
  }

  .terms-desc-small-pl {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.32px;
    margin-bottom: 10px;
    margin-left: 60px;
    text-align: justify;
  }
}
</style>